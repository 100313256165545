<template>
  <Modal
    :isConfirmDisabled="false || isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Confirm"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Activate player</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div v-if="isLoadingData" class="flex justify-center items-center pt-8">
        <em class="fa fa-spinner fa-spin"></em>
      </div>
      <div v-else>
        <h2 v-if="platform === 'Stripe'" class="my-4">
          Before confirming the change to your subscription, we inform you that
          the new amount to be paid on the next invoice will be
          <span class="font-bold">${{ upcomingTotalInvoce }}</span>.
          This total includes adjustments for remaining and unused subscription times.
        </h2>
        <h2 v-else class="my-4">
          Are you sure you want to reactivate {{ playerName }}?
        </h2>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import {
  subscriptionNextInvoiceTotalBeforeQuantityChange,
} from "@/services/stripe/stripe.js";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  isLoading: Boolean,
  platform: String,
  playerName: String,
});

const emit = defineEmits(["update:show", "confirm"]);

const isLoadingData = ref(true);
const isConfirmDisabled = ref(false);
const upcomingTotalInvoce = ref(0);

async function fetchNextInvoiceTotal() {
  if (props.platform === "Stripe") {
    const nextInvoiceTotal =
      await subscriptionNextInvoiceTotalBeforeQuantityChange("increase");
    upcomingTotalInvoce.value = nextInvoiceTotal.next_invoice_total;
  } else if (props.platform === "Apple") {
    upcomingTotalInvoce.value = 0;
  }
  isLoadingData.value = false;
}

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  isLoadingData.value = true;
  emit("update:show", false);
}

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      fetchNextInvoiceTotal();
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
