<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-10">
      <div class="w-100 flex items-center justify-between">
        <h1
          v-if="
            subscriptionPlatform !== 'Stripe' &&
            subscriptionPlatform !== 'Free Trial'
          "
          class="text-white text-lg font-semibold"
        >
          Available seats: {{ availableSeats }}
        </h1>
        <h1 v-else class="text-white text-lg font-semibold">Players</h1>
        <div class="flex items-center justify-end my-3 mt-6">
          <button
            id="addPlayer"
            class="text-color submit-btn font-bold px-6 py-2 w-full"
            @click="addPlayers"
            v-if="subscriptionPlatform !== 'Stripe' && subscriptionPlatform !== 'Free Trial'"
          >
            Add new player
          </button>
        </div>
      </div>
      <div
        class="bg-white flex flex-wrap xl:grid xl:grid-cols-3 xl:grid-rows-1 gap-2 2xl:gap-6 p-6 mt-7"
      >
        <CardPlayer
          v-for="player in players"
          :key="player.id"
          :player="player"
          :isSubscriptionExpired="isSubscriptionExpired"
          :isSubscriptionSuspended="isSubscriptionSuspended"
          :isLoading="isLoading"
          :platform="subscriptionPlatform"
          :expiryDate="subscriptionExpiryDate"
          :availableSeats="availableSeats"
          :noSubscriptionFound="noSubscriptionFound"
          @updateAvailableSeats="updateAvailableSeats"
          @getSubscriptionInfo="getSubscriptionInfo"
        ></CardPlayer>
      </div>
    </div>
  </section>
  <InformationModal
    :show="showNotSeatsModal"
    @update:show="showNotSeatsModal = $event"
    @confirm="showNotSeatsModal = false"
    width="700px"
    :title="'Add new player'"
    :content="getNotAvailableSeatsContent()"
  />
  <InformationModal
    :show="showMaxSeatsModal"
    @update:show="showMaxSeatsModal = $event"
    @confirm="showMaxSeatsModal = false"
    width="700px"
    :title="'Add new player'"
    :content="getMaxAvailableSeatsContent()"
  />
  <InformationModal
    :show="showSubscriptionSuspendedModal"
    @update:show="showSubscriptionSuspendedModal = $event"
    @confirm="showSubscriptionSuspendedModal = false"
    width="700px"
    :title="'Add new player'"
    :content="getSubscriptionSuspendedContent()"
  />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import CardPlayer from "@/components/Players/CardPlayer.vue";
import { getProfiles, getSubscriptionProvider } from "@/services/user/user";
import {
  dependentsToAddOrReactivate,
  getAppStoreTransactionInfo,
} from "@/services/appStore/appStore.js";
import { getStripeSubscriptionInfo } from "@/services/stripe/stripe.js";
import InformationModal from "@/components/profile/modals/InformationModal.vue";

const router = useRouter();

const players = ref([]);
const isSubscriptionExpired = ref(false);
const isSubscriptionSuspended = ref(false);
const isLoading = ref(true);
const availableSeats = ref(0);
const maxSeats = ref(4);
const subscriptionPlatform = ref("Stripe");
const showNotSeatsModal = ref(false);
const showMaxSeatsModal = ref(false);
const subscriptionExpiryDate = ref("");
const showSubscriptionSuspendedModal = ref(false);
const noSubscriptionFound = ref(false);

onMounted(async () => {
  await getSubscriptionInfo();
});

async function getSubscriptionInfo(){
  const subscriptionProvider = await getSubscriptionProvider();
  subscriptionPlatform.value = subscriptionProvider;
  players.value = await getProfiles();

  try {
    let subscription;
    if (subscriptionPlatform.value === "Apple") {
      subscription = await getAppStoreTransactionInfo();
    } else if (subscriptionPlatform.value === "Stripe") {
      subscription = await getStripeSubscriptionInfo();
    } else {
      noSubscriptionFound.value = true;
      isLoading.value = false;
      return;
    }

    isSubscriptionExpired.value = subscription.is_expired;
    subscriptionExpiryDate.value = subscription.subscription_end_date;
    isSubscriptionSuspended.value =
      subscription.subscription_status !== "active";
    await updateAvailableSeats();

    isLoading.value = false;
  } catch (error) {
    isSubscriptionExpired.value = false;
    isSubscriptionSuspended.value = false;
    isLoading.value = false;
    noSubscriptionFound.value = true;
  }
}

async function updateAvailableSeats() {
  const dependentsToProcess = await dependentsToAddOrReactivate();
  if (isSubscriptionSuspended.value) {
    availableSeats.value = 0;
  } else {
    availableSeats.value = dependentsToProcess.available_seats;
  }
}

function addPlayers() {
  if (isSubscriptionSuspended.value) {
    showSubscriptionSuspendedModal.value = true;
  } else if (availableSeats.value === 0) {
    showNotSeatsModal.value = true;
  } else if (
    subscriptionPlatform.value === "Apple" &&
    availableSeats.value >= maxSeats.value
  ) {
    showMaxSeatsModal.value = true;
  } else {
    router.push({
      name: "Register players",
      query: {
        n: availableSeats.value,
        from: "profile",
      },
    });
  }
}

function getNotAvailableSeatsContent() {
  return `Currently, you do not have any available seats to add a new player. 
  You can purchase additional seats from your Apple ID settings.`;
}

function getMaxAvailableSeatsContent() {
  return `At this time, you have reached the maximum number of seats available for your account.`;
}

function getSubscriptionSuspendedContent() {
  return `Your subscription is currently suspended. Please reactivate it from your Apple ID settings.`;
}
</script>

<style>
#addPlayer:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-btn {
  background-color: #edfe86;
  border: none;
}
</style>
